import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Recompense } from 'src/app/models/recompense';
import { RecompenseService } from 'src/app/services/recompense.service';


@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss']
})
export class CatalogueComponent implements OnInit {

  constructor(private router: Router,private recompenseService: RecompenseService) { }
  recompenses: Recompense[] = []
  recompensesTemp: Recompense[] = []
  types?:string[] 
  ngOnInit(): void {
    this.recompenseService.isCatalogue.asObservable().subscribe(data => {
      if(data == true){
        this.scrollTo('catalogue')
      }
    })
    this.recompenseService.recompenses.asObservable().subscribe( data => {
      if(data){
        this.types = data.types
        this.recompenses = data.liste
        this.recompensesTemp = data.liste
        //remove type who dont have recompense 
        this.types = this.types.filter(type => {
          let recompense = this.recompenses.find((recompense:Recompense) => recompense.type == type)
          return recompense != undefined
        })
        //get image for each recompense
        for(let recompense of this.recompenses){
          this.recompenseService.getFileUrl(recompense.img)
          .then(url => {
            recompense.img = url ?? 'assets/images/recompense.png'
          })
        }
      }
    })
  }

  goToRecompense(recompense: Recompense){
    this.recompenseService.recompense.next(recompense)
    this.router.navigateByUrl('/recompense')
  }

  scrollTo(name: string){
    let element = document.getElementById(name) as HTMLElement;
    element.scrollIntoView({behavior: 'smooth'})
  }


  filterType(type: string){
    this.recompensesTemp = this.recompenses.filter(recompense => recompense.type == type)
  }
}
