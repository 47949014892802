<div class="container" id="container" style="padding:5rem 10rem 0rem 10rem">
    <div class="row mb-5">
        <div class="col">
            <div class="retour" (click)="backTo()">
                <img style="margin-right: 1rem;" src="../../../assets/images/chevronLeft.svg" alt="">
                <span>Retour</span>
            </div>
        </div>
    </div>


    <div class="row mb-4" *ngIf="!empty" >
        <div class="col-12 mb-4">
            <div class="row">
                <div class="col-4">
                    <div class="card" style="width: 18rem;border-radius: 15px">
                        <img class="card-img-top" style="border-radius: 15px 15px 0px 0px;" src="{{recompense?.img}}" alt="Image">
                        <div class="card-body text-center">
                            <h5 class="card-title">{{recompense?.titre}}</h5>
                        </div>
                    </div>
                </div>
                <div class="col d-flex justify-content-start">
                    <div class="dataRec">
                        <span class="title">
                            {{recompense?.titre}}
                        </span>
                        <br>
                        <span class="desc">
                            {{recompense?.description}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="board-div roundCard mt-4 mb-4" style="height: 38rem;">
        <div class="mb-4">
            <p class="board-div-tittle">Où nous trouver ?</p>
            <div class="map-custom" style="margin-bottom: 1.5rem;border-radius:15px">
                <google-map [center]="initialCoordinates" [zoom]="this.zoom" style="width: 100%; border-radius: 15px;" width="100%"></google-map>
            </div>
        </div>
        
    </div>

    <div class="mt-4 mb-4" *ngIf="!empty" >
        <h2>Partenaire : <span class="label label-default">{{recompense?.partenaire}}</span></h2>
        <h5><span class="label label-default">{{recompense?.titre}}</span></h5>
        <h5>Nombres de points : <span class="label label-default">{{recompense?.points}}</span></h5>
        <h5>Periode de validité : <span class="label label-default">{{recompense?.validityTimeEnd}}</span></h5>
        <h5>Disponible : <span class="label label-default">{{recompense?.available}}</span></h5>
        <h5>Conditions Générales d'Utilisation : <a href="{{recompense?.cgu}}" class="label label-default">{{recompense?.cgu}}</a></h5>
    </div>

    <div class="row mt-4">
        <div class="col d-flex align-content-center">
            <div class="rdv w-100 d-flex align-content-center">
                <div class="label2 mt-3 mb-4 w-75" style="margin-left:auto; margin-right:auto;display:block;text-align: center;">
                    <div class="row">
                        <span style="font-size: 30px;">Rendez-vous sur l’appli</span>
                    </div>
                    <p class="text-align w-100" >Sélectionnez vos récompenses sur l'application et présentez-les à votre commerçant pour bénéficier de vos réductions !</p>
                    <button type="button" class="btn dl-button">Ouvrir</button>
                </div>
               
            </div>
        </div>
    </div>
</div>
