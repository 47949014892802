<div class="container">
    <span class="h1 mb-2">
        Mes données personnelles
    </span>
    <div class="row">
        <div class="col">
            <form class="mb-4 w-100" [formGroup]="settingsForm" (ngSubmit)="submitUserdata()">
                <div class="row mt-4 mb-4">
                    <div class="col-7">
                        <div class="form-group row mb-2">
                            <div class="col">
                                <div class="inner-addon-s left-addon">
                                    <img src="../../../assets/images/mail.svg" alt="">
                                    <input type="email" class="form-control customInput" FormControlName="email" [value]="emailUser" placeholder="Mon email" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col align-self-center">
                        <span class="changePass" (click)="forgotPassword()">Modifier mon mot de passe</span>
                    </div>
                    <div class="w-100"></div>
                    <div class="col-7">
                        <div class="form-group row mb-2">
                            <div class="col">
                                <div class="inner-addon-s left-addon">
                                    <img src="../../../assets/images/user.svg" alt="">
                                    <input type="text" class="form-control customInput" FormControlName="prenom" placeholder="Mon nom" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col align-self-center">
                    </div>
                </div>
              
                <label class="form-switch mb-2" style="padding-left:0% !important">
                    <input type="checkbox" checked>
                    <i></i>
                    J'accepte les Conditions générales d'utilisation
                </label>
                    <label class="form-switch" style="padding-left:0% !important">
                    <input type="checkbox" checked>
                    <i></i>
                J'accepte la Charte de confidentialité
                </label>
                <div style="margin-top: 4rem;">
                    <span class="h1 mb-2" >
                        Votre adresse
                    </span>
                    <div>
                        <div class="form-group row mt-4 mb-2">
                            <div class="col">
                                <div class="inner-addon-s left-addon">
                                    <img src="../../../assets/images/user.svg" alt="">
                                    <input type="text" *ngIf="address" class="form-control customInput" FormControlName="adresse" placeholder="{{address}}" />
                                    <AutocompleteComponent *ngIf="!address" (setAddress)="getAddress($event)" adressType="geocode"></AutocompleteComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button type="submit" class="btn dl-button mt-4">Enregistrer</button>
            </form>
        </div>
        <div class="col">

        </div>
    </div>

   
    


    <div class="row mt-2">
        <div class="col d-flex align-content-center" >
            <div class="rdv w-100 d-flex align-content-center" style="margin-top: 5rem;">
                <div class="label2 w-75 mt-3 mb-4 " style="margin-left:auto; margin-right:auto;display:block;text-align: center;">
                    <div class="row mb-2">
                        <span style="font-size: 30px;">Des questions ?</span>
                    </div>
                    <p class="text-align w-100" >Nous avons des réponses ! Contactez-nous sur <a href="mailto:bonjour@montri.fr">bonjour@montri.fr</a><br> </p>
                    <!-- <button type="button" class="btn aide-button">Ouvrir le centre d'aide
                       
                    </button> -->
                </div>
               
            </div>
        </div>
    </div>
   
</div>
