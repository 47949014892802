// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
      apiKey: "AIzaSyAavM2ST3zrmAoRBo6skktuykjOZ7h9j0o",
      authDomain: "uzer-triact.firebaseapp.com",
      projectId: "uzer-triact",
      storageBucket: "uzer-triact.appspot.com",
      messagingSenderId: "296188983125",
      appId: "1:296188983125:web:7287ff355ccd440bbca1dc"
  },
  api: {
    rootUrl: 'https://montri-service-lpdg6cesca-ez.a.run.app',
    secretKey: '707c0023-ebc8-4ae7-8a4b-5d20fd75bafc'
  } 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
