import { Component, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserBo } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  user!:UserBo
  userAdress?:any
  settingsForm!: FormGroup
  address?: any;
  emailUser:string = ""
  formattedAddress!: string;
  constructor(private authService: AuthService, private fb: FormBuilder,public zone: NgZone) { }

  ngOnInit(): void {
    if(localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user')!).user
      this.address = this.user.adresse
      this.emailUser = this.user.email ?? ""
    }

    this.authService.userData.asObservable().subscribe(data => {
      if(data) {
        this.user = data.user 
        this.address = this.user.adresse
        this.emailUser = this.user.email ?? ""
      }
    })

    this.settingsForm = this.fb.group({
      email: [this.user?.email, [ Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      prenom: [this.user?.prenom],
    })

  }

  submitUserdata() {
    this.user.email = this.settingsForm.value.email ?? this.user?.email
    this.user.prenom = this.settingsForm.value.prenom ?? this.user?.prenom
    this.user.adresse = this.address ?? this.user?.adresse
    this.authService.setUserCollection(this.settingsForm.value)
  }

  getAddress(place: any) {
    this.address = place['formatted_address'] ?? '';
    this.formattedAddress = place['formatted_address'];
    this.zone.run(() => this.formattedAddress = place['formatted_address']);
  }

  forgotPassword() {
    if(this.emailUser) this.authService.ForgotPassword(this.emailUser)
  }

 

}
