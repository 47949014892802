import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountComponent } from './components/account/account.component';
import { RecompenseComponent } from './components/recompense/recompense.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import {redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { SettingsComponent } from './components/settings/settings.component';
import { CatalogueComponent } from './components/catalogue/catalogue.component';
import { AuthGuard } from './services/auth.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'catalogue', component: CatalogueComponent },
    { path: 'recompense', component: RecompenseComponent },
    { path: 'login', component: LoginComponent },
    { path: 'account', component: AccountComponent, canActivate: [AuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }, },
    { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard],  data: { authGuardPipe: redirectUnauthorizedToLogin }, },
    { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
