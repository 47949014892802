import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Timestamp } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  constructor(
    public afs: AngularFirestore, // Inject Firestore service
  ) { }


  async addUserNewsletter(uid:string,email: string) {
    if(!uid){
      uid = this.afs.createId()
    }
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `newsletterTriact/${uid}`
    );
    const data = {
      email: email,
      dateAjout: Timestamp.now(),
    };
    //check if exist in newsletter by email
    const user = await this.afs.collection('newsletterTriact', ref => ref.where('email', '==', email)).get().toPromise()
    if(user.docs.length === 0){
      return userRef.set(data, {
        merge: true,
      });
    }
  }
}
