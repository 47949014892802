import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EmailPassword, LoggedUser } from 'src/api/v1/models';
import { AuthenticationService } from 'src/api/v1/services';
import { Niveau } from 'src/app/models/contrat';
import { Recompense } from 'src/app/models/recompense';
import { Contrat, Stats } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { RecompenseService } from 'src/app/services/recompense.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  user:any
  prenom?:string
  nom?:string
  contrat?:Contrat
  stats?:Stats
  img? : string
  score?:string
  niveau?: any
  recompenses: any[] = [
    {
      nom:'Réservées', 
      color: 'rgba(67, 189, 68, 0.25)',
      description:'Vous recevrez un bon d’achat pour chaque commande effectuée sur notre site.',
      recompenses:[
        {
          titre:'NOM RECOMPENSE',
          description:'DESCRIPTION RECOMPENSE',
          img:'assets/images/recompense.png'
        },
        {
          titre:'NOM RECOMPENSE',
          description:'DESCRIPTION RECOMPENSE',
          img:'assets/images/recompense.png'
        },
        {
          titre:'NOM RECOMPENSE',
          description:'DESCRIPTION RECOMPENSE',
          img:'assets/images/recompense.png'
        },
      ]
    },
    {
      nom:'Utilisées', 
      color: 'rgba(187, 107, 217, 0.2)',
      description:'Vous recevrez un bon d’achat pour chaque commande effectuée sur notre site.',
      recompenses:[
        {
          titre:'NOM RECOMPENSE',
          description:'DESCRIPTION RECOMPENSE',
          img:'assets/images/recompense.png'
        },
        {
          titre:'NOM RECOMPENSE',
          description:'DESCRIPTION RECOMPENSE',
          img:'assets/images/recompense.png'
        },
        {
          titre:'NOM RECOMPENSE',
          description:'DESCRIPTION RECOMPENSE',
          img:'assets/images/recompense.png'
        },
      ]
    }
  ]
  constructor(private authService: AuthService,private router:Router,private recompenseService: RecompenseService, private montriAuthenticationService: AuthenticationService) { }

  ngOnInit(): void {
    if(localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user')!).user
      this.prenom = this.user.prenom
      this.nom = this.user.nom
      this.img = this.user.img
      this.contrat = this.user.contrat
      this.stats = this.user.contrats["ROUEN TRI ACT"].stats
      this.score = this.user.score
      this.niveau = this.user.niveau
      console.log(this.stats)
    }
    this.authService.userData.asObservable().subscribe(data => {
      if(data) {
        this.user = data.user
        this.prenom = this.user .prenom ?? "Utilisateur"
        this.nom = this.user .nom ?? ""
        this.authService.currentContrat.asObservable().subscribe(data => {
          this.user.contracts.map((contrat:Contrat) => {
            if(contrat.nom == data){
                this.authService.contrat.asObservable().subscribe(contratData =>{
                  let scoreParNiveau: number = 0
                  this.recompenses = contrat.listeRecompensesReservees
                  this.stats = contrat.stats
                  this.contrat = contrat
                  this.niveau = contratData.niveau
                  for(let lvl in this.niveau){
                    if(this.niveau[lvl].level == this.stats.niveau.level){
                      scoreParNiveau = this.niveau[lvl].points 
                      break
                    }
                  }
                  this.img = `../../../assets/images/niveau/mask-level-${this.stats.niveau.level}.png`
                  this.score = String((this.stats?.niveau?.points *100/scoreParNiveau)+"%")
                })
              }
          })
        })
      }
    })
    
  }

  

  //Go to recompense page with recompense 
  goToRecompense(recompense: Recompense){
    this.recompenseService.recompense.next(recompense)
    this.router.navigateByUrl('/recompense')
  }


  redirectToApp() {
    window.open("https://montri.page.link/application", '_blank');
  }

}
