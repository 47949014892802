<div class="container" >
    <img class="imgBackground" src="../../../assets/images/image 8.svg" alt="">
    <div class="row d-flex">
        <div class="col d-flex justify-content-md-center justify-content-start">
            <div class="row mt-5 ml-5">
                <div class="col-6 w-100 text-left">
                    <p class="join-us w-100 mt-5">Rejoignez-nous sur Montri et gagnez des récompenses</p>
                    <div class="row">
                        <div class="col d-flex justify-content-left">
                            <button type="button" class="btn dl-button" (click)="redirectToApp()"> Télécharger</button>
                        </div>
                       
                    </div>
                    
                </div>      
            </div>
        </div>
        <div class="col-md-6 align-middle justify-content-lg-center">
            <img class="phoneBg w-100" src="../../../assets/images/image 9.svg" alt="">
        </div>
    </div>
    <div class="row mt-5" >
        <div class="col d-flex justify-content-center">
            <div class="label1">
                <span>Et si on vous <span class="orange">récompensait</span> pour votre <span class="orange">tri</span> ?</span>
                <div class="containerVideo mt-5">
                    <iframe class="video" width="830" height="500" src="https://www.youtube-nocookie.com/embed/3BYUaj-ksNs?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </div>
        </div>
        <div class="w-100 d-none d-md-block"></div>
         <div class="col d-flex justify-content-center" style="margin-top: -2rem;">
        </div>
    </div>
   
   
    <div class="row" style="margin-top: 7rem;">
        <div class="col w-100 d-flex justify-content-center justify-content-md-end">
            <img class="phoneBg2" src="../../../assets/images/image 12.png" alt="">
        </div>
        <div class="col d-flex justify-content-start">
            <div class="label2 w-100 text-center text-md-start">
                <span>Inscrivez-vous sur l’appli <span class="orange">Montri</span> </span>
                <p>Téléchargez gratuitement Montri, renseignez votre code postal, et inscrivez-vous pour créer votre profil de trieur.</p>
            </div>
        </div>
    </div>

    <div class="row" style="margin-top: 7rem;">
        <div class="col-6 d-flex justify-content-end" >
            <div class="label2" style="width: 84% !important;">
                <span>Triez vos emballages et gagnez des <span class="orange">points</span> </span>
                <p>Les points sont des points virtuels que vous gagnez en utilisant l'application pour vous récompenser pour le tri de vos emballages.</p>
            </div>
        </div>
        <div class="col d-flex justify-content-start">
            <img class="phoneBg2" src="../../../assets/images/image 11.png" alt="">
        </div>
    </div>

    <div class="row" style="margin-top: 7rem;">
        <div class="col d-flex justify-content-end">
            <img class="phoneBg2" src="../../../assets/images/image 10.png" alt="">
        </div>
        <div class="col d-flex justify-content-start">
            <div class="label2">
                <span>Utilisez vos points chez nos <span class="orange">partenaires</span> </span>
                <p>Dépensez vos points chez l'un de nos nombreux partenaires.</p>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col d-flex align-content-center">
            <div class="rdv w-75 d-flex align-content-center">
                <div class="label2 mt-3 mb-4 w-75" style="margin-left:auto; margin-right:auto;display:block;text-align: center;">
                    <div class="row">
                        <span style="font-size: 30px;">Rendez-vous sur l’appli</span>
                    </div>
                    <p class="text-align w-100" >Sélectionnez vos récompenses sur l'application et présentez-les à votre commerçant pour bénéficier de vos réductions !</p>
                    <button type="button" class="btn dl-button" (click)="redirectToApp()">Télécharger</button>
                </div>
               
            </div>
        </div>
    </div>
    <!-- catalogue  -->
    <div id="catalogue">
        <div class="mt-5 d-flex justify-content-center" >
            <p class="winText">Ce que vous pouvez gagner 🎁</p>
        </div>
        
    
        <div class="mb-2">
            <div *ngFor="let type of types">
                <div class="row d-flex justify-content-left ml-4">
                    <div class="col-4 ml-4 d-flex justify-content-start">
                        <div class=" mt-4 w-auto mb-4 d-flex justify-content-center textTitle" style="background: rgba(67, 189, 68, 0.25);" >
                            <p class="px-2">{{type}}</p>
                        </div>
                    </div>
                </div>
                <div class="row mb-4" >
                    <ng-container *ngFor="let element of recompenses | slice:0:4; let i=index">
                        <div  *ngIf="element.type == type" class="col-4 d-flex justify-content-left " (click)="goToRecompense(element)">
                            <div class="card" style="width: 18rem;border-radius: 15px; cursor: pointer;">
                                <img class="card-img-top" style="border-radius: 15px 15px 0px 0px;" src="{{element.img ?? '../../../../assets/images/recompense.png' }}" alt="">
                                <div class="card-body text-center">
                                  <h5 class="card-title">{{element.titre}}</h5>
                                </div>
                              </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="row w-100 d-flex justify-content-center">
                <button type="button" class="btn btn-warning showAll" (click)="goToCatalogue()"><p>Voir tout</p> </button>
            </div>
        </div>
    </div>
    
      <!-- fin catalogue  -->

    <div class="mt-5 d-flex justify-content-center">
        <span class="winText"><span class="orange">Comment&nbsp;</span> ça marche ?</span>
    </div>

    <div class="tuto w-75 left1 mt-4 mb-2">
        <div class="row" style="padding: 3.2rem;">
            <div class="col" style="margin-left:auto; margin-right:auto;display:block">
                <span class="step">Etape 1</span>
                <span class="tutoTitle">Choisissez votre mode de collecte</span>
                <span class="tutoDescription">Que vous soyez collecté en bacs, en sacs, ou en apport volontaire, tout le monde peut participer.Commencez par définir votre mode de collecte.</span>
            </div>
            <div class="col d-flex justify-content-end">
                <img class="tutoImgRight" src="../../../assets/images/tuto1.svg" alt="">
            </div>
        </div>
    </div>

    <div class="tuto w-75 left2 mt-4 mb-2">
        <div class="row" style="padding: 3.2rem;">
            <div class="col d-flex justify-content-start">
                <img class="tutoImgLeft"  src="../../../assets/images/tuto2.svg" alt="">
            </div>
            <div class="col" style="margin-left:auto; margin-right:auto;display:block">
                <span class="step">Etape 2</span>
                <span class="tutoTitle">Scannez vos emballages et ajoutez les à votre cabas</span>
                <span class="tutoDescription">Scannez vos emballages pour savoir comment les trier, et ajoutez vos emballages recyclables dans votre panier de tri virtuel.Pour chaque emballage, 1 point gagné </span>
            </div>
        </div>
    </div>

    <div class="tuto w-75 left1 mt-4 mb-2">
        <div class="row" style="padding: 3.2rem;">
            <div class="col" style="margin-left:auto; margin-right:auto;display:block">
                <span class="step">Etape 3</span>
                <span class="tutoTitle">Déposez votre panier et gagnez des points</span>
                <span class="tutoDescription">Suivez les instructions pour déposer vos emballages en fonction de votre mode de collecte.Une fois validé, vous êtes crédité.e et vous retrouvez vos points dans votre compte.</span>
            </div>
            <div class="col d-flex justify-content-end">
                <img class="tutoImgRight" src="../../../assets/images/tuto3.svg" alt="">
            </div>
        </div>
    </div>

    <div class="tuto w-75 left2 mt-4 mb-4">
        <div class="row" style="padding: 3.2rem;">
            <div class="col d-flex justify-content-start">
                <img class="tutoImgLeft" src="../../../assets/images/tuto4.svg" alt="">
            </div>
            <div class="col" style="margin-left:auto; margin-right:auto;display:block">
                <span class="step">Etape 4</span>
                <span class="tutoTitle">Echangez vos points contre des réompenses</span>
                <span class="tutoDescription">Accédez au catalogue de récompenses et sélectionnez celles qui vous intéressent.Présentez-les chez les commerçants partenaires pour les valider et bénéficier de réduction et autres cadeaux ! </span>
            </div>
        </div>
    </div>

    <div class="mt-5 mb-2 d-flex justify-content-center">
        <p class="winText">Ils adorent</p>
    </div>

    <div class="row mb-4" style=" flex-wrap: nowrap !important;overflow: auto !important;">
        <div class="col" *ngFor="let element of avis">
            <div class="card text-center" style="width: 18rem;">
                <div class="card-body">
                    <div class="row mb-2" style="left: 35%;position: relative;">
                        {{getStars(element.note)}}
                    </div>
                  <h5 class="card-title">{{element.nom}}</h5>
                  <p class="card-text">{{element.description}}</p>
                </div>
              </div>
        </div>
    </div>

    <div class="row mt-5">
        <div class="col d-flex align-content-center">
            <div class="rdv w-75 d-flex align-content-center">
                <div class="label2 mt-3 w-75" style="margin-left:auto; margin-right:auto;display:block;text-align: center;">
                    <div class="row">
                        <span style="font-size: 30px;">Restez informé.e</span>
                    </div>
                    <p class="text-align w-100">Abonnez-vous à la newsletter pour rester informé de nos actualités et recevoir le catalogue de récompenses en avant-première !</p>
                    <!-- input with left icon -->
                    <form class="mb-4 w-50" style="margin-left: 25%;" [formGroup]="newsletterForm" (ngSubmit)="submitNewsletterForm()">
                        <div class="form-group row mb-2">
                            <div class="col">
                                <div class="inner-addon-s left-addon">
                                    <img src="../../../assets/images/mail.svg" alt="">
                                    <input type="email" class="form-control" placeholder="Mon email" FormControlName="email" (change)="getEmail($event)"  style="background: white;border: none;"/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
               
            </div>
        </div>
    </div>
</div>
