import { Component } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { disconnect } from 'process';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from 'src/api/v1/services/authentication.service';
import { LoginComponent } from './components/login/login.component';
import { AuthService } from './services/auth.service';
import { JwtService } from './services/jwt.service';
import { NewsletterService } from './services/newsletter.service';
import { RecompenseService } from './services/recompense.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'landing';
  user:any
  isConnected:boolean = false
  date = new Date();
  newsletterForm!: FormGroup;
  height:any
  path?:string
  bottom?:string
  username: string = 'Utilisateur';
  isMobile: BehaviorSubject <boolean> = new BehaviorSubject(this.checkIsMobile())
  token?: string

  constructor(
    private router: Router,
    private modalService : NgbModal,
    private authService: AuthService,
    private fb: FormBuilder,
    private db : Firestore,
    private recompenseService: RecompenseService,
    private newsletterService: NewsletterService,
    private toastr: ToastrService
  ) {}

  async ngOnInit() {
    if(localStorage.getItem('user')) {
      this.user = JSON.parse(localStorage.getItem('user')!).user
      this.username = this.user.prenom ?? 'Utilisateur'
      this.isConnected = true
    }

    this.recompenseService.getAllRecompenses(this.db,"CONTRAT TEST") 
    this.newsletterForm = this.fb.group({
      email: [null, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
   
    this.authService.userData.asObservable().subscribe(data => {
      if(data) {
        this.user = data.user
        this.isConnected = true
        this.username = this.user.prenom ?? "Utilisateur"
        this.authService.getCurrentContratData("CONTRAT TEST")
        this.authService.setCurrentContrat("CONTRAT TEST")
      }
    })

    // this.authService.user.asObservable().subscribe(data => {
    //   if(data) {
    //     this.username = data.prenom ?? data.displayName ?? "Utilisateur"
    //   }
    // })
  }
  

 checkIsMobile() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      return true
    }else{
      return false
    }
  }

  regexCheckMail(mail:string){
    let regex = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")
    return regex.test(mail)
  }


  submitNewsletterForm(){
    if(this.newsletterForm.valid){
      this.newsletterService.addUserNewsletter(this.user.uid,this.newsletterForm.value.email)
      this.toastr.success('Vous etes inscrit à la newsletter', 'Succès');
    }
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url)
  }

  toCatalogue() {
    this.recompenseService.isCatalogue.next(true)
    this.router.navigateByUrl('/home')
  }

  disconnect(){
    this.authService.logout()
  }

  getEmail(event:any){
    this.newsletterForm.get('email')?.setValue(event.target.value) ?? "";
  }

  showModal(){
    window.scrollTo(0, 0);
    let options: NgbModalOptions = {};
    options.centered = true
    options.size = "md"
    options.windowClass = "modal-adaptive"
    this.modalService.dismissAll()
    const modalRef = this.modalService.open(LoginComponent,options)
    // modalRef.componentInstance.modalTitle = modalTitle;
    // modalRef.componentInstance.type = type;
    modalRef.result.then((result) => {
        if(result.connected){
          this.router.navigateByUrl('/account');
        }
    })

    
}

}
