import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { EmailPassword, LoggedUser } from 'src/api/v1/models';
import { AuthenticationService } from 'src/api/v1/services';
import { Recompense } from 'src/app/models/recompense';
import { AuthService } from 'src/app/services/auth.service';
import { NewsletterService } from 'src/app/services/newsletter.service';
import { RecompenseService } from 'src/app/services/recompense.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  newsletterForm!: FormGroup;

  user: any;
  recompenses: Recompense[] = [];
  types?: string[];
  avis: any = [
    {
      nom: 'Lorem ipsum dolor sit amet',
      description:
        'Sonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris',
      note: 4,
    },
    {
      nom: 'Lorem',
      description:
        'Sonsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      note: 5,
    },
    {
      nom: 'Lorem ipsum',
      description:
        'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto',
      note: 4,
    },
    {
      nom: 'NOM AVIS',
      description: 'DESCRIPTION AVIS',
      note: 5,
    },
    {
      nom: 'NOM AVIS',
      description: 'DESCRIPTION AVIS',
      note: 3,
    },
    {
      nom: 'NOM AVIS',
      description: 'DESCRIPTION AVIS',
      note: 5,
    },
  ];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private montriAuthenticationService: AuthenticationService,
    private recompenseService: RecompenseService,
    private authService: AuthService,
    private newsletterService: NewsletterService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    // const obj: EmailPassword = { email: 'juanpablo.delaplata@gmail.com', password: 'aaaaaA1*' };
    // this.montriAuthenticationService
    //   .userControllerSignInWithEmailAndPassword({ body: obj })
    //   .subscribe((res: LoggedUser) => {
   
    //   });
    
    this.recompenseService.isCatalogue.asObservable().subscribe((data) => {
      if (data == true) {
        this.scrollTo('catalogue');
      }
    });
    this.authService.userData.asObservable().subscribe((data) => {
      if (data) {
        this.user = data;
      }
    });
    this.recompenseService.recompenses.asObservable().subscribe((data) => {
      if (data) {
        this.types = data.types;
        this.recompenses = data.liste;
        //remove type who dont have recompense
        this.types = this.types.filter((type) => {
          let recompense = this.recompenses.find(
            (recompense: Recompense) => recompense.type == type
          );
          return recompense != undefined;
        });
        //get image for each recompense
        for (let recompense of this.recompenses) {  
          this.recompenseService.getFileUrl(recompense.img).then((url) => {
            recompense.img = url ?? "assets/images/recompense.png";
          });
        }
      }
    });

    this.newsletterForm = this.fb.group({
      email: [
        null,
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
    });
  }

  getEmail(event:any){
    this.newsletterForm.get('email')?.setValue(event.target.value) ?? "";
  }

  submitNewsletterForm(){
    if(this.newsletterForm.valid){
      this.newsletterService.addUserNewsletter(this.user.uid,this.newsletterForm.value.email)
      this.toastr.success('Vous etes inscrit à la newsletter', 'Succès');
    }
  }

  redirectToApp() {
    window.open("https://montri.page.link/application", '_blank');
  }

  scrollTo(name: string) {
    let element = document.getElementById(name) as HTMLElement;
    element.scrollIntoView({ behavior: 'smooth' });
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  goToCatalogue() {
    this.recompenseService.isCatalogue.next(true);
    this.router.navigateByUrl('/catalogue');
  }

  //Go to recompense page with recompense
  goToRecompense(recompense: Recompense) {
    this.recompenseService.recompense.next(recompense);
    this.router.navigateByUrl('/recompense');
  }

  getStars(nb: number) {
    let star = '⭐️';
    let stars = '';
    for (let i = 0; i < nb; i++) {
      stars += star + ' ';
    }
    return stars;
  }
}


