<!-- navbar at the left logo and at the right Accueil, Catalogue, Mon compte  -->

  
    <nav class="navbar navbar-expand-lg navbar-light bg-light" style="background-color: transparent !important;">
        <a class="navbar-brand" href="/home">
            <img class="logo" src="../assets/images/logo.svg" alt="">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class=" nav navbar-nav navbar-right">
                <li class="nav-item active" *ngIf="!isConnected" (click)="navigateTo('/home')">
                    <a class="nav-link" href="/home">Accueil</a>
                </li>
                <li class="nav-item" (click)="toCatalogue()">
                    <a class="nav-link">Catalogue</a>
                </li>
                <li class="nav-item nav-account"  *ngIf="!isConnected" style="cursor: pointer" (click)="showModal()">
                    <a class="nav-link" >Mon compte</a>
                </li>
        
                <li *ngIf="isConnected" class="nav-item dropdown dropCustom" ngbDropdown>
                  <a class="nav-link dropdown-toggle" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
                    👋 {{username}}
                  </a>
                  <ul class="dropdown-menu"  ngbDropdownMenu aria-labelledby="navbarScrollingDropdown">
                    <li ngbDropdownItem><a class="dropdown-item" href="/account">Compte</a></li>
                    <li ngbDropdownItem><a class="dropdown-item" href="/settings">Paramètres</a></li>
                    <li ngbDropdownItem><hr class="dropdown-divider"></li>
                    <li ngbDropdownItem><a class="dropdown-item" (click)="disconnect()">Me deconnecter</a></li>
                  </ul>
                </li>
            </ul>
        </div>
    </nav>





<router-outlet>
</router-outlet>


<footer   class=" col-sm-12 text-center text-lg-start text-muted w-100" style="z-index: 10;">
  <img class="imgBackground2" src="../../../assets/images/image 13.png" alt="">
  <section style="margin-top: -12rem;" class="">
    <div class=" text-center text-md-start mt-5">
      <div class="row mt-3">

        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">
            Fait avec 💛
          </h6>
          <p>
            <a href="/home" class="text-reset">Accueil</a>
          </p>
          <p>
            <a href="/catalogue" class="text-reset">Catalogue</a>
          </p>
          <p>
            <a href="/account" class="text-reset">Mon compte</a>
          </p>
        </div>

        
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">
            En partenariat avec 
          </h6>
          <div> 
            <img style="width: 100%;" src="../assets/images/logoPaprec.png" alt="">
            <img style="width: 100%;" src="../assets/images/logoRouen.png" alt="">
          </div>          
        </div>
 
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          
          <h6 class="text-uppercase fw-bold mb-4">Abonnez-vous pour rester informé.e</h6>
          <form class="mb-4 w-100" [formGroup]="newsletterForm" (keyup.enter)="submitNewsletterForm()" (ngSubmit)="submitNewsletterForm()">
            <div class="form-group row mb-2">
                <div class="col-10">
                    <div class="inner-addon-s left-addon">
                        <img src="../assets/images/mail.svg" alt="">
                        <input type="email" class="form-control" FormControlName="email" (change)="getEmail($event)" (keyup)="getEmail($event)"  placeholder="Mon email" />
                    </div>
                </div>
                <div class="col-2">
                  <img class="w-100" style="cursor: pointer;" [src]="regexCheckMail(newsletterForm.get('email')?.value ?? '') ? '../assets/images/inputGreen.svg':'../assets/images/inputWhite.svg'" alt="">
                </div>
            </div>
        </form>
    
        </div>

      </div>

    </div>
  </section>

</footer>