import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from './auth.service';


@Injectable()

export class AuthGuard implements CanActivate {

    returnValue: any

    constructor(private router: Router, private auth: AuthService) {
    }

    canActivate(): boolean {
      if (!this.auth.isAuthenticated()) {
        this.router.navigate(['home']);
        return false;
      }
      return true;
    }
}



