/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { EmailPassword } from '../models/email-password';
import { LoggedUser } from '../models/logged-user';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation userControllerSignInWithEmailAndPassword
   */
  static readonly UserControllerSignInWithEmailAndPasswordPath = '/v1/user/signInWithEmailAndPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerSignInWithEmailAndPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerSignInWithEmailAndPassword$Response(params: {
    context?: HttpContext
    body: EmailPassword
  }
): Observable<StrictHttpResponse<LoggedUser>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticationService.UserControllerSignInWithEmailAndPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoggedUser>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerSignInWithEmailAndPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userControllerSignInWithEmailAndPassword(params: {
    context?: HttpContext
    body: EmailPassword
  }
): Observable<LoggedUser> {

    return this.userControllerSignInWithEmailAndPassword$Response(params).pipe(
      map((r: StrictHttpResponse<LoggedUser>) => r.body as LoggedUser)
    );
  }

  /**
   * Path part for operation userControllerSignInAnonymously
   */
  static readonly UserControllerSignInAnonymouslyPath = '/v1/user/signInAnonymously';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerSignInAnonymously()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerSignInAnonymously$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LoggedUser>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticationService.UserControllerSignInAnonymouslyPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoggedUser>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerSignInAnonymously$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerSignInAnonymously(params?: {
    context?: HttpContext
  }
): Observable<LoggedUser> {

    return this.userControllerSignInAnonymously$Response(params).pipe(
      map((r: StrictHttpResponse<LoggedUser>) => r.body as LoggedUser)
    );
  }

  /**
   * Path part for operation userControllerIsTokenValid
   */
  static readonly UserControllerIsTokenValidPath = '/v1/user/isTokenValid/{token}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerIsTokenValid()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerIsTokenValid$Response(params: {
    token: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticationService.UserControllerIsTokenValidPath, 'get');
    if (params) {
      rb.path('token', params.token, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerIsTokenValid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerIsTokenValid(params: {
    token: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.userControllerIsTokenValid$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation userControllerLogOut
   */
  static readonly UserControllerLogOutPath = '/v1/user/logOut/{uid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerLogOut()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerLogOut$Response(params: {
    uid: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AuthenticationService.UserControllerLogOutPath, 'get');
    if (params) {
      rb.path('uid', params.uid, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `userControllerLogOut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userControllerLogOut(params: {
    uid: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.userControllerLogOut$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
