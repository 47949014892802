<div class="container ml-4 mr-4" >
    <img class="imgBackground" src="../../../assets/images/image 8.svg" alt="">
    <div class="row d-flex">
        <div class="col-6 d-flex justify-content-end">
            <div class="row mt-5 ml-5">
                <div class="col-6 w-100">
                    <p class="join-us">Plus de 800 partenaires vous récompensent!</p>
                    <button type="button" class="btn mt-2 dl-button w-75" onclick="location.href='mailto:bonjour@montri.fr';">Rejoindre les partenaires</button>
                </div>      
            </div>
        </div>
        <div class="col-4">
            <img class="phoneBg" src="../../../assets/images/image 9.svg" alt="">
        </div>
    </div>

    <div id="catalogue" class="label2 text-center w-100 mt-2 mb-5">
        <span>Il y en a pour <span class="orange">tous</span> les goûts...</span>
    </div>

    <div class="">
        <div class="row d-flex justify-content-center">
            <div class="col-2 d-flex justify-content-start" *ngFor="let type of types">
                <div class="mt-4 w-100 mb-4 d-flex justify-content-center textTitle" (click)="filterType(type)" style="background: rgba(67, 189, 68, 0.25);border-radius: 15px;cursor: pointer;" >
                    <p>{{type}}</p>
                </div>
            </div>
        </div>
        
        <div class="mb-2">
            <div class="row mb-4 " >
                <ng-container *ngFor="let element of recompensesTemp">
                    <div  class="col-4 mb-4 d-flex justify-content-center " (click)="goToRecompense(element)">
                        <div class="card" style="width: 18rem;border-radius: 15px; cursor: pointer;">
                            <img class="card-img-top" style="border-radius: 15px 15px 0px 0px;" src="{{element.img}}" alt="Image">
                            <div class="card-body text-center">
                              <h5 class="card-title">{{element.titre}}</h5>
                            </div>
                          </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    

    <div class="label2 text-center w-100">
        <span>... et <span class="orange">partout</span></span>
    </div>



    <div class="row mt-2">
        <div class="col d-flex align-content-center" >
            <div class="rdv w-100 d-flex align-content-center" style="margin-top: 5rem;">
                <div class="label2 w-75 mt-3 mb-4 " style="margin-left:auto; margin-right:auto;display:block;text-align: center;">
                    <div class="row mb-2">
                        <span style="font-size: 30px;">Des questions ?</span>
                    </div>
                    <p class="text-align w-100" >Nous avons des réponses ! Contactez-nous sur <a href="mailto:bonjour@montri.fr">bonjour@montri.fr</a><br> </p>
                    <!-- <button type="button" class="btn aide-button">Ouvrir le centre d'aide
                  
                    </button> -->
                </div>
               
            </div>
        </div>
    </div>

</div>
