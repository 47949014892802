<!-- angular form login password -->
<div class="container">
    <button type="button" (click)="close()" class="btn-close close" data-bs-dismiss="modal" aria-label="Close"></button>
    <ng-container *ngIf="!isSignUp">
        <span class="title mb-4">Connexion</span>
        <form  class="mb-2 w-75 d-flex justify-content-center" [formGroup]="formLogin" (ngSubmit)="onSubmit()">
            <div class="form-group row mb-2 text-center">
                <div class="col">
                    <div class="inner-addon-s left-addon">
                        <img src="../../../assets/images/mail.svg" alt="">
                        <input style="border: none;" type="email" (keyup)="getEmail($event)" (change)="getEmail($event)" FormControlName="email" class="form-control" placeholder="Mon email" required/>
                    </div>
                </div>
                <div class="w-100"></div>
                <div class="col mt-4">
                    <div class="inner-addon-s left-addon">
                        <img class="lockCustom" src="../../../assets/images/password.svg" alt="">
                        <input style="border: none;" type="password" (keyup)="getPassword($event)" (change)="getPassword($event)" FormControlName="password" class="form-control" placeholder="Mon mot de passe" required />
                    </div>
                </div>
                <div class="w-100"></div>
                <div class="col d-flex justify-content-center mt-4">
                    <button type="submit" class="connect w-100 mt-2 mb-4">Me connecter</button>
                </div>
            </div>
        </form>
        <div *ngIf="errorLogin">
            <div class="alert alert-danger" role="alert">
                <strong>Email ou mot de passe incorrect !</strong> 
            </div>
        </div>

        <!-- <button class="button" (click)="passwordReset()">reset</button> -->

        
        <div class="row w-100" s>
            <div class="col d-flex justify-content-start">
                <span>Pas encore inscrit ?</span>
            </div>
            <div class="col d-flex justify-content-end">
                <span style="font-weight: 900;cursor:pointer" (click)="signUp()">Par ici 👉</span>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="isSignUp">
        <span class="title mb-4">Inscrivez-vous</span>
        <img class="qrcode mb-4" src="../../../assets/images/QRcode-MONTRI.svg" alt="">
        <span class="text-center w-75 mb-4 textDl">Téléchargez l’application Montri et inscrivez-vous au programme Tri Act pour gagner des récompenses en triant !</span>
        <div class="row">
            <div class="col">
                <a href="https://apps.apple.com/fr/app/montri-trier-solidaire/id1458927235">
                    <img src="../../../assets/images/App-store.svg" alt="">
                </a>
            </div>
            <div class="col">
                <a href="https://play.google.com/store/apps/details?id=eu.uzer.montri&hl=fr">
                    <img src="../../../assets/images/Google-Play.svg" alt="">
                </a>
            </div>
        </div>

    </ng-container>
    
</div>
