import { Injectable } from '@angular/core';
import { doc, Firestore, getDocs, onSnapshot, setDoc, collection, getDoc } from 'firebase/firestore';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { BehaviorSubject } from 'rxjs';
import { Recompense, Recompenses } from '../models/recompense';

@Injectable({
  providedIn: 'root'
})
export class RecompenseService {
  recompense: BehaviorSubject<Recompense | undefined> = new BehaviorSubject<Recompense | undefined>(undefined)
  recompenses: BehaviorSubject<Recompenses | undefined> = new BehaviorSubject<Recompenses | undefined>(undefined)
  isModalOpen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  updateRecompense: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  isCatalogue : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  
  constructor() { }
  async getAllRecompenses(db:Firestore,contract: string) {
    const recompensesRef = doc(db, 'contrats', contract, 'data', 'recompenses');
    const docSnap = await getDoc(recompensesRef);
    if (docSnap.exists()) {
      this.recompenses.next(docSnap.data() as Recompenses)
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  async setRecompenses(db:Firestore,recompense: Recompense, contract: string) {
    const bornesRef = await setDoc(doc(db, "contrats", contract, "data", "recompenses", ), {liste: recompense}, {merge: true})
  }

  async getFileUrl(path:string): Promise<string> {
    let defaultUrl = "assets/images/recompense.png"
    const storage = getStorage();
    const starsRef = ref(storage, path);
    await getDownloadURL(starsRef).then((url) => {
      defaultUrl = url;
    }).catch((error) => {
      console.log("error: ",error);
    })
    return defaultUrl
  }
}
