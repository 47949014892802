import { forwardRef, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { AccountComponent } from './components/account/account.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RecompenseComponent } from './components/recompense/recompense.component';
import { environment } from 'src/environments/environment';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth.guard';
import { GoogleMapsModule } from '@angular/google-maps';
import { RecompenseService } from './services/recompense.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreModule,
} from '@angular/fire/compat/firestore';
import { ApiInterceptor } from 'src/api/api-interceptor';
import { SettingsComponent } from './components/settings/settings.component';
import { CatalogueComponent } from './components/catalogue/catalogue.component';
import { AutocompleteComponent } from './utils/autocomplete/autocomplete.component';
import { ApiModule } from 'src/api/v1/api.module';
import { JwtService } from './services/jwt.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true,
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    AccountComponent,
    RecompenseComponent,
    SettingsComponent,
    CatalogueComponent,
    AutocompleteComponent,
  ],
  imports: [
    ApiModule.forRoot({ rootUrl: environment.api.rootUrl }),
    HttpClientModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    GoogleMapsModule,
    ReactiveFormsModule,
    NgbModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFirestoreModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    provideFirebaseApp(() => {
      return initializeApp(environment.firebase);
    }),
    provideAuth(() => {
      return getAuth();
    }),
    provideStorage(() => {
      return getStorage();
    }),
    provideFirestore(() => {
      return getFirestore();
    }),
  ],
  providers: [
    JwtService,
    ApiInterceptor,
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    API_INTERCEPTOR_PROVIDER,
    AuthGuard,
    AngularFirestore,
    NgbActiveModal,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
