import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {GoogleMap, MapInfoWindow} from "@angular/google-maps";
import { Router } from '@angular/router';
import { Recompense } from 'src/app/models/recompense';
import { RecompenseService } from 'src/app/services/recompense.service';

@Component({
  selector: 'app-recompense',
  templateUrl: './recompense.component.html',
  styleUrls: ['./recompense.component.scss']
})
export class RecompenseComponent implements OnInit {
  marqueur = [
    '<?xml version="1.0" encoding="UTF-8" standalone="no"?>',
    '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">',
    '<svg width="90px" height="90px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">',
    '<rect id="Plan-de-travail1" serif:id="Plan de travail1" x="0" y="0" width="50" height="50" style="fill:none;"/>',
    '<g id="Plan-de-travail11" serif:id="Plan de travail1">',
        '<g transform="matrix(0.971084,0,0,0.977779,25,23.0018)">',
        '<path d="M0,-23.024C-10.011,-23.024 -18.156,-14.879 -18.156,-4.868C-18.156,7.557 -1.907,25.798 -1.216,26.57C-0.567,27.292 0.567,27.291 1.217,26.57C1.907,25.798 18.156,7.557 18.156,-4.868C18.156,-14.879 10.013,-23.024 0,-23.024Z" style="fill:{{ color }};fill-rule:nonzero;"/>',
        '</g>',
    '</g>',
    '<image href="{{ image }}" x="17" y="13" height="15" width="15" />',
    '</svg>',
    ].join('\n')
  zoom = 10
  markers: google.maps.Marker[] = []
  initialCoordinates = {
    lat: 48.856614,
    lng: 2.3522219
  }
  recompense?:Recompense
  empty:boolean = true
  @ViewChild(GoogleMap, {static: false}) map: GoogleMap | undefined
  @ViewChild(MapInfoWindow, {static: false}) info: MapInfoWindow | undefined
  constructor(private recompenseService: RecompenseService, private router: Router) { }

  ngOnInit(): void {
    const map = document.getElementsByClassName('map-container') as HTMLCollectionOf<HTMLElement>
    //change border radius of map
    map[0].style.borderRadius = "15px"
    this.recompenseService.recompense.asObservable().subscribe(data => {
      if(data){
        this.recompense = data
        this.empty = false
        let element = document.getElementById("container") as HTMLElement;
        if(element) element.scrollIntoView({behavior: 'smooth'})
        this.initialCoordinates = {
          lat: this.recompense.lat ?? this.initialCoordinates.lat,
          lng: this.recompense.lng ?? this.initialCoordinates.lng
        }
        this.addMarker(this.recompense)
      
      }else{
        this.recompenseService.isCatalogue.next(true)
        this.router.navigateByUrl('/catalogue')
      }
    })
  }

  centerWithBounds() {
    let bounds = new google.maps.LatLngBounds()
    for (let i in this.markers) {
        // @ts-ignore
        bounds.extend(this.markers[i].getPosition())
    }
    this.map?.fitBounds(bounds)
}

  

  addMarker(recompense:Recompense) {
    // const LatLng = new google.maps.LatLng(recompense.lat, recompense.lng)
    const markerobj = {
      position: {
        lat: Number(recompense.lat),
        lng: Number(recompense.lng),
      },
      clickable: true,
      map: this.map?.googleMap,
      title: recompense.titre + (this.markers.length + 1),
      options: {
        animation: google.maps.Animation.BOUNCE,
        icon: {
          url:
            'data:image/svg+xml;charset=UTF-8,' +
            encodeURIComponent(this.marqueur),
          scaledSize: {
            width: 45,
            height: 45,
          },
        },
      },
    };
    let marker = new google.maps.Marker(markerobj)
    this.markers.push(marker)
    this.centerWithBounds()
  }

  backTo(){
    window.history.back();
  }

}


