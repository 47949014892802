<div class="container" >
    <span class="h1 mb-2">
        👋 Bonjour {{prenom}}
    </span>
    <div class="row mb-2" style="margin-top: 5rem;">
        <div class="col">
            <div class="stats-graph">
                <div class="col d-flex justify-content-center mb-2">
                    <img src="{{img ?? '../../../assets/images/niveau/mask-level-1.png'}}" alt="">
                </div> 
                <div class="w-100"></div>
                <div class="col mb-2 statsName d-flex justify-content-center">
                    <span>{{prenom}} {{nom}}</span>
                </div>
                <div class="w-100"></div>
                <div class="col mb-2 d-flex justify-content-center">
                    <span>{{ stats?.niveau?.nom ?? "Recycleur débutant"}}</span>
                </div>
                <div class="w-100"></div>
                <div class="col d-flex justify-content-center">
                    <div class="row" style="display: ruby;">
                        <div class="progress">
                            <div class="progress-bar" role="progressbar"  aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width': score ? score : '0%' }"></div>
                        </div>
                        <span>{{ score ?? "0%"}}</span>
                    </div>
                  
                </div>
            </div>
        </div>
        <div class="col">
            <div class="row stats-num">
                <div class="col mb-4">
                    Points disponibles : {{stats?.pointsTriAct ?? "0"}}
                </div>
                <div class="w-100"></div>
                <div class="col mb-4">
                    Total points collectés : {{stats?.pointsTriActCollecte ?? "0"}}
                </div>
                <div class="w-100"></div>
                <div class="col mb-4">
                    Total points dépensés : {{stats?.pointsTriActUtilise ?? "0"}}
                </div>
            </div>
        </div>
    </div>
    <span class="h1" >
        Mes récompenses
    </span>

    <div class="mt-4 mb-2">
        <div  *ngFor="let recompense of recompenses">
            <div class=" mt-4 mb-2 textTitle d-flex justify-content-center" style="margin-left: 5%;width: 15%;"
            [ngStyle]="{'background' : recompense.nom == 'Utilisées' ? 'rgba(67, 189, 68, 0.25)' : 'rgba(243, 245, 249, 1)'}">
                <span [ngStyle]="{'color' : recompense.nom ==  'Utilisées' ? 'rgba(67, 189, 68, 1)':'rgba(106, 112, 126, 1)'}">
                    {{recompense.nom}}</span>
            </div>
            <div class="row mb-4">
                <div *ngFor="let element of recompense.recompenses" class="col d-flex justify-content-center mb-4">
                    <div class="card" (click)="goToRecompense(element)" style="width: 18rem;border-radius: 15px; cursor: pointer;">
                        <img class="card-img-top" style="border-radius: 15px 15px 0px 0px;" src="{{element.img ?? '../../../../assets/images/recompense.png' }}" alt="">
                        <div class="card-body text-center">
                          <h5 class="card-title">{{element.titre}}</h5>
                        </div>
                      </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-2">
        <div class="col d-flex align-content-center"  style="margin-top: 5rem;">
            <div class="rdv w-100 d-flex align-content-center">
                <div class="label2 w-75 mt-3 mb-4 " style="margin-left:auto; margin-right:auto;display:block;text-align: center;">
                    <div class="row">
                        <span style="font-size: 30px;">Rendez-vous sur l’appli</span>
                    </div>
                    <p class="text-align w-100" >Sélectionnez vos récompenses sur l'application et présentez-les à votre commerçant pour bénéficier de vos réductions !</p>
                    <button type="button" class="btn dl-button" (click)="redirectToApp()">Télécharger</button>
                </div>
               
            </div>
        </div>
    </div>

</div>
