import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
const jose = require('jose')

@Injectable()
export class JwtService {
    private _token: string = ''

    get token(): string {
        if (this._token === '')
        {
            throw new Error('Invalid token. Have you run "await JwtService.generateJWT()".')
        }
        const token = this._token
        this._token = ''
        
        return token
    }
    
    public async generateJWT(): Promise<string> {
        const secretKey = Buffer.from(environment.api.secretKey)

        this._token = await new jose.SignJWT({})
            .setProtectedHeader({alg: 'HS256'})
            .setIssuedAt()
            .setExpirationTime('15m')
            .sign(secretKey); 
        return this._token
    }

}