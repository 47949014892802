import { Injectable, NgZone } from '@angular/core';
import { UserBo } from '../models/user';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Auth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from '@angular/fire/auth';
import { LoginData } from '../models/login';
import { JwtService } from './jwt.service';
import { AuthenticationService } from 'src/api/v1/services';
import { EmailPassword, LoggedUser } from 'src/api/v1/models';


@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user?: LoggedUser
  userData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  contrat: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  currentContrat: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    public afs: AngularFirestore, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private auth: Auth,
    private jwtService: JwtService,
    private montriAuthenticationService: AuthenticationService,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe((user) => {
      if (user?.email && user?.uid) {
        // this.user.next(user);
        this.userData.next(user);
      }
    })

    if(localStorage.getItem('user')) {
      this.userData.next(JSON.parse(localStorage.getItem('user') || '{}'));
    }
  }


  async login({ email, password }:EmailPassword):Promise<boolean> {
    let isConnected:boolean = false
    await this.jwtService.generateJWT()
    const obj: EmailPassword = {email, password};
    try {
      await this.montriAuthenticationService.userControllerSignInWithEmailAndPassword({body:obj}).toPromise().then((user) => {
        this.user = user 
        this.userData.next(user);
        isConnected = true
        localStorage.setItem('user', JSON.stringify(user));
        return true
      })
    } catch (error) {
      console.log("error :", error);
      isConnected = false
    }
    return isConnected
  }

  register({ email, password }: LoginData) {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  logout() {
    this.montriAuthenticationService.userControllerLogOut({uid: this.userData.value.uid})
    this.userData.next(null);
    localStorage.removeItem('user');
    window.location.reload();
  }

  isAuthenticated(): boolean {
    return localStorage.getItem('user') ? true : false || this.userData.value ? true : false
  }


  // Reset Forggot password
  ForgotPassword(passwordResetEmail: string) {
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Votre mot de passe a été réinitialisé, vérifiez votre boite mail');
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    if (!this.auth.currentUser) return false;
    return true;
  }
  

  async getCurrentContratData(contratName: any) {
    this.afs.collection("contrats").doc(contratName).get().subscribe((doc) => {
      this.contrat.next(doc.data());
    })
  }


  setCurrentContrat(contrat: any) {
    this.currentContrat.next(contrat);
  }

  async setUserCollection(user: UserBo) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    return userRef.set(user, {
      merge: true,
    });
  }

}