import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserBo } from 'src/app/models/user';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  formLogin!: FormGroup
  isSignUp: boolean = false;
  errorLogin: boolean = false;
  user?:UserBo
  error?: string

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private router : Router
  ) {}

  ngOnInit(): void {
    this.authService.userData.asObservable().subscribe(data => {
      if(data) {
        this.user = data
        if(this.user?.email && this.user?.uid) {
          this.activeModal.close({ connected: true});
          this.router.navigate(['/account'])
        }
      }
    })
    this.formLogin = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  close() {
    this.activeModal.close({ connected: false });
  }

  getEmail(event:any){
    this.formLogin.get('email')?.setValue(event.target.value) ?? "";
  }

  getPassword(event:any){
    this.formLogin.get('password')?.setValue(event.target.value) ?? "";
  }

  passwordReset() {
    this.authService.ForgotPassword(this.formLogin.value.email)
  }

  signUp() {
    this.isSignUp = !this.isSignUp;
  }

  async onSubmit() {
      await this.authService.login(this.formLogin.value).then((isConnected) => {
        if(isConnected) {
          console.log('isConnected', isConnected)
          this.errorLogin = false
          this.activeModal.close({ connected: true });
          this.router.navigate(['/account'])
        }else{
          console.log('isConnected', isConnected)
          this.errorLogin = true;
        }
      })
  }
}
