/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Produit } from '../models/produit';

@Injectable({
  providedIn: 'root',
})
export class DemoService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation demoControllerGetAll
   */
  static readonly DemoControllerGetAllPath = '/v1/demo/repo/get/all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demoControllerGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  demoControllerGetAll$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<Produit>>> {

    const rb = new RequestBuilder(this.rootUrl, DemoService.DemoControllerGetAllPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Produit>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `demoControllerGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demoControllerGetAll(params?: {
    context?: HttpContext
  }
): Observable<Array<Produit>> {

    return this.demoControllerGetAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Produit>>) => r.body as Array<Produit>)
    );
  }

  /**
   * Path part for operation demoControllerGet
   */
  static readonly DemoControllerGetPath = '/v1/demo/repo/get/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demoControllerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  demoControllerGet$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Produit>> {

    const rb = new RequestBuilder(this.rootUrl, DemoService.DemoControllerGetPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Produit>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `demoControllerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demoControllerGet(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Produit> {

    return this.demoControllerGet$Response(params).pipe(
      map((r: StrictHttpResponse<Produit>) => r.body as Produit)
    );
  }

  /**
   * Path part for operation demoControllerCreate
   */
  static readonly DemoControllerCreatePath = '/v1/demo/repo/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demoControllerCreate()` instead.
   *
   * This method doesn't expect any request body.
   */
  demoControllerCreate$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Produit>> {

    const rb = new RequestBuilder(this.rootUrl, DemoService.DemoControllerCreatePath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Produit>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `demoControllerCreate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demoControllerCreate(params?: {
    context?: HttpContext
  }
): Observable<Produit> {

    return this.demoControllerCreate$Response(params).pipe(
      map((r: StrictHttpResponse<Produit>) => r.body as Produit)
    );
  }

  /**
   * Path part for operation demoControllerDelete
   */
  static readonly DemoControllerDeletePath = '/v1/demo/repo/delete/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demoControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  demoControllerDelete$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DemoService.DemoControllerDeletePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `demoControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demoControllerDelete(params: {
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.demoControllerDelete$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation demoControllerGetEntity
   */
  static readonly DemoControllerGetEntityPath = '/v1/demo/entity/get/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demoControllerGetEntity()` instead.
   *
   * This method doesn't expect any request body.
   */
  demoControllerGetEntity$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Produit>> {

    const rb = new RequestBuilder(this.rootUrl, DemoService.DemoControllerGetEntityPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Produit>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `demoControllerGetEntity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demoControllerGetEntity(params: {
    id: string;
    context?: HttpContext
  }
): Observable<Produit> {

    return this.demoControllerGetEntity$Response(params).pipe(
      map((r: StrictHttpResponse<Produit>) => r.body as Produit)
    );
  }

  /**
   * Path part for operation demoControllerCreateEntity
   */
  static readonly DemoControllerCreateEntityPath = '/v1/demo/entity/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demoControllerCreateEntity()` instead.
   *
   * This method doesn't expect any request body.
   */
  demoControllerCreateEntity$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Produit>> {

    const rb = new RequestBuilder(this.rootUrl, DemoService.DemoControllerCreateEntityPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Produit>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `demoControllerCreateEntity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demoControllerCreateEntity(params?: {
    context?: HttpContext
  }
): Observable<Produit> {

    return this.demoControllerCreateEntity$Response(params).pipe(
      map((r: StrictHttpResponse<Produit>) => r.body as Produit)
    );
  }

  /**
   * Path part for operation demoControllerDeleteEntity
   */
  static readonly DemoControllerDeleteEntityPath = '/v1/demo/entity/delete/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demoControllerDeleteEntity()` instead.
   *
   * This method doesn't expect any request body.
   */
  demoControllerDeleteEntity$Response(params: {
    id: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DemoService.DemoControllerDeleteEntityPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `demoControllerDeleteEntity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demoControllerDeleteEntity(params: {
    id: string;
    context?: HttpContext
  }
): Observable<void> {

    return this.demoControllerDeleteEntity$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
